export enum MaterialType {
  GuardianAnimalCard = 1,
  SpiritOfNatureStandee,
  ProtectiveTreeTiles,
  CircleOfSpiritBoard,
  VictoryTile,
  ForestBoard,
  FireTile,
  FragmentTile,
  SacredTree
}