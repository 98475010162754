import { RuleId } from '../RuleId'

export const rockRules = [
  RuleId.PlantTree,
  RuleId.TakeFragment,
  RuleId.AttractAnimals,
  RuleId.TakeFragment,
  RuleId.ExtinguishFire,
  RuleId.TakeFragment,
  RuleId.PlantTree,
  RuleId.TakeFragment,
  RuleId.AttractAnimals,
  RuleId.TakeFragment,
  RuleId.ExtinguishFire,
  RuleId.TakeFragment
]