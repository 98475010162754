export enum LocationType {
  Table = 1,
  ForestBoard,
  CircleOfSpiritBoardSpace,
  PlayerDeckStack,
  PlayerDiscardStack,
  ReserveStack,
  ReserveRow,
  TreeDispenser,
  VictoryTileArea,
  VaranDeck,
  FragmentStack,
  HelpLine,
  FireStack,
  CircleOfSpiritBoardFire,
  SacredTree,
  TreeSpace,
  PlayerFireTileStack,
  PlayerFragmentTileStack,
  PlayerReminder
}