import { getEnumValues } from '@gamepark/rules-api'

enum GuardianAnimal {
  Bear = 1,
  Beetle,
  Lynx,
  Fox,
  Goldfish,
  Tanuki,
  Boar,
  Weasel,
  Hare,
  Fawn,
  Hummingbird,
  Bee,
  Baboon,
  Owl,
  Hedgehog,
  Caterpillar,
  Flamingo,
  Ram,
  Wolf,
  Lemur,
  Squirrel,
  Tortoise,
  Coati,
  Racoon,
  Beaver,
  Raven,
  Bat,
  Cockatoo,
  Meerkat,
  Horse,
  Axolotl,
  Chimpanzee,
  Toucan,
  Butterfly,
  Spider,
  Hippopotamus,
  HornedOwl,
  Woodpecker,
  Badger,
  Rooster,
  Rhinoceros,
  Goat,
  Bull,
  Salamander,
  Frog,
  Marmot,
  Platypus,
  Crane,
  Loris,
  Koala,
  Leopard,
  Eagle,
  Cobra,
  Cricket,
  Panther,
  Gorilla,
  Bison,
  Chameleon,
  Crocodile,
  Elephant,
  Dolphin,
  Dog,
  Panda,
  Stag,
  Tapir,
  FireVaran
}

export const guardianAnimals = getEnumValues(GuardianAnimal)
export const startingGuardianAnimals = guardianAnimals.slice(0, 14)

export const isVaran = (guardianAnimal: GuardianAnimal) => GuardianAnimal.FireVaran === guardianAnimal

export default GuardianAnimal
