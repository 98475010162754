import AttractGuardian from './attractGuardian.png'
import AutumnPlayerBoard from './autumn-player-board.png'
import autumnSpiritCircle from './autumnSpiritCircle.png'
import autumnVictoryFire from './autumnVictoryFire.png'
import autumnVictorySacredFlower from './autumnVictorySacredFlower.png'
import autumnVictoryTree from './autumnVictoryTree.png'

import circleOfSpirits from './circleOfSpirits.png'
import ExtinguishFire from './extinguishFire.png'
import fire from './fire.png'
import fire2 from './fire2.png'
import fire3 from './fire3.png'
import fire4 from './fire4.png'
import firePanel from './firePanel.png'
import treePanel from './treePanel.png'
import fragment from './fragment.png'
import MoveCircle from './moveCircle.png'

import PlantTree from './plantTree.png'

import ReserveHolder from './reserve-holder.png'
import drop from './resources/drop.png'
import gregarious from './resources/gregarious.png'
import sacredFlower from './resources/sacredFlower.png'
import seed from './resources/seed.png'
import solitary from './resources/solitary.png'
import sun from './resources/sun.png'
import wind from './resources/wind.png'
import SacredTree from './sacred-tree.png'

import SpringPlayerBoard from './spring-player-board.png'
import springSpiritCircle from './springSpiritCircle.png'

import springVictoryFire from './springVictoryFire.png'
import springVictorySacredFlower from './springVictorySacredFlower.png'
import springVictoryTree from './springVictoryTree.png'
import SummerPlayerBoard from './summer-player-board.png'
import summerSpiritCircle from './summerSpiritCircle.png'


import summerVictoryFire from './summerVictoryFire.png'
import summerVictorySacredFlower from './summerVictorySacredFlower.png'
import summerVictoryTree from './summerVictoryTree.png'
import TakeFragment from './takeFragment.png'
import tree10 from './trees/tree10.jpg'
import tree11 from './trees/tree11.jpg'

import tree3A from './trees/tree3A.jpg'
import tree3B from './trees/tree3B.jpg'
import tree4A from './trees/tree4A.jpg'
import tree4B from './trees/tree4B.jpg'
import tree5A from './trees/tree5A.jpg'
import tree5B from './trees/tree5B.jpg'
import tree6 from './trees/tree6.jpg'
import tree7 from './trees/tree7.jpg'
import tree8 from './trees/tree8.jpg'
import tree9 from './trees/tree9.jpg'
import VaranHolder from './varan-holder.png'
import WinterPlayerBoard from './winter-player-board.png'
import winterSpiritCircle from './winterSpiritCircle.png'

import winterVictoryFire from './winterVictoryFire.png'
import winterVictorySacredFlower from './winterVictorySacredFlower.png'
import winterVictoryTree from './winterVictoryTree.png'

const Images = {
  tree3A, tree3B, tree4A, tree4B, tree5A, tree5B, tree6, tree7, tree8, tree9, tree10, tree11,
  springSpiritCircle, springVictoryFire, springVictorySacredFlower, springVictoryTree,
  summerSpiritCircle, summerVictoryFire, summerVictorySacredFlower, summerVictoryTree,
  autumnVictoryFire, autumnVictorySacredFlower, autumnVictoryTree, autumnSpiritCircle,
  winterSpiritCircle, winterVictoryFire, winterVictorySacredFlower, winterVictoryTree,
  circleOfSpirits,
  sun, drop, seed, wind, sacredFlower, treePanel, gregarious, solitary,
  fragment, fire, fire2, fire3, fire4, firePanel,
  SacredTree,
  SpringPlayerBoard,
  SummerPlayerBoard,
  WinterPlayerBoard,
  AutumnPlayerBoard,
  ReserveHolder,
  VaranHolder,
  TakeFragment, ExtinguishFire, PlantTree, MoveCircle, AttractGuardian
}

export default Images
