import CardType from './CardType'
import GuardianAnimal from './GuardianAnimal'
import {
    Axolotl,
    Baboon,
    Badger,
    Bat,
    Bear,
    Beaver,
    Bee,
    Beetle,
    Bison,
    Boar,
    Bull,
    Butterfly,
    Caterpillar,
    Chameleon,
    Chimpanzee,
    Coati,
    Cobra,
    Cockatoo,
    Crane,
    Cricket,
    Crocodile,
    Dog,
    Dolphin,
    Eagle,
    Elephant,
    Fawn,
    FireVaran,
    Flamingo,
    Fox,
    Frog,
    Goat,
    Goldfish,
    Gorilla,
    Hare,
    Hedgehog,
    Hippopotamus,
    HornedOwl,
    Horse,
    Hummingbird,
    Koala,
    Lemur,
    Leopard,
    Lynx,
    Marmot,
    Meerkat,
    Owl,
    Panda,
    Panther,
    Platypus,
    Racoon,
    Ram,
    Raven,
    Rhinoceros,
    Rooster,
    Salamander,
    Sloth,
    Spider,
    Squirrel,
    Stag,
    Tanuki,
    Tapir,
    Tortoise,
    Toucan,
    Weasel,
    Wolf,
    Woodpecker
} from './GuardiansAnimals'
import { Resource } from './Resource'


type GuardianAnimalDetails = {
    resources: { [key in Resource]?: number }
    cost: number
    type?: CardType
}

export default GuardianAnimalDetails

export const GuardianAnimalDescriptions: Record<GuardianAnimal, GuardianAnimalDetails> = {
    [GuardianAnimal.Bear]: Bear,
    [GuardianAnimal.Beetle]: Beetle,
    [GuardianAnimal.Lynx]: Lynx,
    [GuardianAnimal.Fox]: Fox,
    [GuardianAnimal.Goldfish]: Goldfish,
    [GuardianAnimal.Tanuki]: Tanuki,
    [GuardianAnimal.Boar]: Boar,
    [GuardianAnimal.Weasel]: Weasel,
    [GuardianAnimal.Hare]: Hare,
    [GuardianAnimal.Fawn]: Fawn,
    [GuardianAnimal.Hummingbird]: Hummingbird,
    [GuardianAnimal.Bee]: Bee,
    [GuardianAnimal.Baboon]: Baboon,
    [GuardianAnimal.Owl]: Owl,
    [GuardianAnimal.Hedgehog]: Hedgehog,
    [GuardianAnimal.Caterpillar]: Caterpillar,
    [GuardianAnimal.Flamingo]: Flamingo,
    [GuardianAnimal.Ram]: Ram,
    [GuardianAnimal.Wolf]: Wolf,
    [GuardianAnimal.Lemur]: Lemur,
    [GuardianAnimal.Squirrel]: Squirrel,
    [GuardianAnimal.Tortoise]: Tortoise,
    [GuardianAnimal.Coati]: Coati,
    [GuardianAnimal.Racoon]: Racoon,
    [GuardianAnimal.Beaver]: Beaver,
    [GuardianAnimal.Raven]: Raven,
    [GuardianAnimal.Bat]: Bat,
    [GuardianAnimal.Cockatoo]: Cockatoo,
    [GuardianAnimal.Meerkat]: Meerkat,
    [GuardianAnimal.Horse]: Horse,
    [GuardianAnimal.Axolotl]: Axolotl,
    [GuardianAnimal.Chimpanzee]: Chimpanzee,
    [GuardianAnimal.Toucan]: Toucan,
    [GuardianAnimal.Butterfly]: Butterfly,
    [GuardianAnimal.Spider]: Spider,
    [GuardianAnimal.Hippopotamus]: Hippopotamus,
    [GuardianAnimal.HornedOwl]: HornedOwl,
    [GuardianAnimal.Woodpecker]: Woodpecker,
    [GuardianAnimal.Badger]: Badger,
    [GuardianAnimal.Rooster]: Rooster,
    [GuardianAnimal.Rhinoceros]: Rhinoceros,
    [GuardianAnimal.Goat]: Goat,
    [GuardianAnimal.Bull]: Bull,
    [GuardianAnimal.Salamander]: Salamander,
    [GuardianAnimal.Frog]: Frog,
    [GuardianAnimal.Marmot]: Marmot,
    [GuardianAnimal.Platypus]: Platypus,
    [GuardianAnimal.Crane]: Crane,
    [GuardianAnimal.Loris]: Sloth,
    [GuardianAnimal.Koala]: Koala,
    [GuardianAnimal.Leopard]: Leopard,
    [GuardianAnimal.Eagle]: Eagle,
    [GuardianAnimal.Cobra]: Cobra,
    [GuardianAnimal.Cricket]: Cricket,
    [GuardianAnimal.Panther]: Panther,
    [GuardianAnimal.Gorilla]: Gorilla,
    [GuardianAnimal.Bison]: Bison,
    [GuardianAnimal.Chameleon]: Chameleon,
    [GuardianAnimal.Crocodile]: Crocodile,
    [GuardianAnimal.Elephant]: Elephant,
    [GuardianAnimal.Dolphin]: Dolphin,
    [GuardianAnimal.Dog]: Dog,
    [GuardianAnimal.Panda]: Panda,
    [GuardianAnimal.Stag]: Stag,
    [GuardianAnimal.Tapir]: Tapir,
    [GuardianAnimal.FireVaran]: FireVaran
}

export function countSolitary(animals: GuardianAnimal[]): number {
    return animals.reduce((sum, animal) => sum + ((GuardianAnimalDescriptions[animal].type == CardType.Solitary) ? 1 : 0), 0)
}
export function countGregarious(animals: GuardianAnimal[]): number {
    return animals.reduce((sum, animal) => sum + ((GuardianAnimalDescriptions[animal].type == CardType.Gregarious) ? 1 : 0), 0)
}
export function getSolitaryGregariousDifference(animals: GuardianAnimal[]): number {
    return countSolitary(animals) - countGregarious(animals)
}